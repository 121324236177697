import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { useState } from 'react';
import { withPrefix } from 'gatsby';
import Playground from '../../../components/Playground';
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export { Link, Pagination } from 'hds-react';
export const _frontmatter = {
  "slug": "/components/pagination/code",
  "title": "Pagination - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "basic",
      "style": {
        "position": "relative"
      }
    }}>{`Basic`}<a parentName="h4" {...{
        "href": "#basic",
        "aria-label": "basic permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [pageIndex, setPageIndex] = useState(0);
  return (
    <Pagination
      language="en"
      onChange={(event, index) => {
        event.preventDefault();
        setPageIndex(index);
      }}
      pageCount={9}
      pageHref={() => '#'}
      pageIndex={pageIndex}
      paginationAriaLabel="Pagination 1"
      siblingCount={9}
    />
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-pagination-container">
  <nav class="hds-pagination" role="navigation" aria-label="Pagination" data-next="Next">
    <button type="button" disabled class="hds-button hds-button--supplementary hds-button--theme-black hds-pagination__button-prev">
      <span aria-hidden="true" class="hds-icon hds-icon--angle-left"></span>
      <span class="hds-button__label">Previous</span>
    </button>
    <ul class="hds-pagination__pages">
      <li>
        <a class="hds-pagination__item-link hds-pagination__item-link--active" href="#" title="Current page" aria-label="Page 1" aria-current="page">
          1
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 2" title="Go to page 2">
          2
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#"  aria-label="Page 3" title="Go to page 3">
          3
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 4" title="Go to page 4">
          4
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 5" title="Go to page 5">
          5
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 6" title="Go to page 6">
          6
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 7" title="Go to page 7">
          7
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 8" title="Go to page 8">
          8
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 9" title="Go to page 9">
          9
        </a>
      </li>
    </ul>
    <button type="button" class="hds-button hds-button--supplementary hds-button--theme-black hds-pagination__button-next">
      <span class="hds-button__label">Next</span>
      <span aria-hidden="true" class="hds-icon hds-icon--angle-right"></span>
    </button>
  </nav>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "with-truncation",
      "style": {
        "position": "relative"
      }
    }}>{`With truncation`}<a parentName="h4" {...{
        "href": "#with-truncation",
        "aria-label": "with truncation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [pageIndex, setPageIndex] = useState(31);
  return (
    <Pagination
      language="en"
      onChange={(event, index) => {
        event.preventDefault();
        setPageIndex(index);
      }}
      pageCount={68}
      pageHref={() => '#'}
      pageIndex={pageIndex}
      paginationAriaLabel="Pagination 2"
      siblingCount={2}
    />
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-pagination-container">
  <nav class="hds-pagination" role="navigation" aria-label="Pagination" data-next="Next">
    <button type="button" class="hds-button hds-button--supplementary hds-button--theme-black hds-pagination__button-prev">
      <span aria-hidden="true" class="hds-icon hds-icon--angle-left"></span>
      <span class="hds-button__label">Previous</span>
    </button>
    <ul class="hds-pagination__pages">
      <li>
        <a class="hds-pagination__item-link" href="#" title="Go to page 1" aria-label="Page 1">
          1
        </a>
      </li>
      <li>
        <span class="hds-pagination__item-ellipsis">
          ...
        </span>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 30" title="Go to page 30">
          30
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 31" title="Go to page 31">
          31
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link hds-pagination__item-link--active" href="#" aria-label="Page 32" title="Current page" aria-current="page">
          32
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 33" title="Go to page 33">
          33
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 34" title="Go to page 34">
          34
        </a>
      </li>
      <li>
        <span class="hds-pagination__item-ellipsis">
          ...
        </span>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 68" title="Go to page 68">
          68
        </a>
      </li>
    </ul>
    <button type="button" class="hds-button hds-button--supplementary hds-button--theme-black hds-pagination__button-next">
      <span class="hds-button__label">Next</span>
      <span aria-hidden="true" class="hds-icon hds-icon--angle-right"></span>
    </button>
  </nav>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "without-previous-and-next-buttons",
      "style": {
        "position": "relative"
      }
    }}>{`Without previous and next buttons`}<a parentName="h4" {...{
        "href": "#without-previous-and-next-buttons",
        "aria-label": "without previous and next buttons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [pageIndex, setPageIndex] = useState(31);
  return (
    <Pagination
      hideNextButton
      hidePrevButton
      language="en"
      onChange={(event, index) => {
        event.preventDefault();
        setPageIndex(index);
      }}
      pageCount={68}
      pageHref={() => '#'}
      pageIndex={pageIndex}
      paginationAriaLabel="Pagination 3"
      siblingCount={2}
    />
  );
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-pagination--basic" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/pagination" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-pagination--basic" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/pagination" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-pagination--basic" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dataTestId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data test id of pagination.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hds-pagination`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hideNextButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hides the next button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hidePrevButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hides the previous button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Language of the component.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fi`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback fired when the page is changed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pageCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of pages.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pageHref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function for generating the href of pages.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pageIndex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The active page index.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`paginationAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for the pagination nav element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`siblingCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of always visible pages before and after the current page.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`theme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Theme prop for customisation of the Pagination component.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      